$primary-black: #000000;
$primary-black-33: #00000021;
$primary-white: #FFFFFF;
$primary-white-10: #FFFFFF1A;
$primary-gradient: linear-gradient(148.54deg, #FF1A58 12.07%, #8026FF 87.57%);

$secondary-red: #FF1A58;
$secondary-red-opacity-10: #FF1A581A;
$secondary-white: #FFFFFF;
$secondary-dark-red: #AD0047;
$secondary-violet: #8026FF;
$secondary-violet-10: #8026FF1A;
$secondary-dark-violet: #402491;
$secondary-gray: #B0B0B0;
$secondary-dark-gray: #6E6E6E;
$secondary-light-gray: #EEEEEE;
$secondary-light-gray-33: #EEEEEE21;
$secondary-dark-charcoal: #333333;

$tertiary-green: #4FB947;
$tertiary-green-10: #4FB9471A;
$tertiary-orange: #FF841C;
$tertiary-dark-green: #2D8628;

$clickable-red-back: #FFE9EF;
$clickable-violet: #8026FF19;
$clickable-red-alert: #FF2828;

$min-desktop-width: 1751px;
$max-tablet-width: 1750px;
$medium-tablet-width: 760px;
$min-tablet-width: 361px;
$max-mobile-width: 360px;
