// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './custom-theme' as *;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$entergy-primary: mat.define-palette(mat.$indigo-palette);
$entergy-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$entergy-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$entergy-theme: mat.define-light-theme((color: (primary: $entergy-primary,
  accent: $entergy-accent,
  warn: $entergy-warn,
),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($entergy-theme);

@import '~maplibre-gl/dist/maplibre-gl.css';

//html, body { height: 100%; }
body {
  margin: 0;
  font-family: 'Proxima Nova', Arial, Roboto, "Helvetica Neue", sans-serif !important;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 100;
  src: url(assets/fonts/Mark-Simonson-Proxima-Nova-Thin.otf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: url(assets/fonts/Mark-Simonson-Proxima-Nova-Semibold.otf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: bold;
  src: url(assets/fonts/Mark-Simonson-Proxima-Nova-Bold.otf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  src: url(assets/fonts/Mark-Simonson-Proxima-Nova-Extrabold.otf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 900;
  src: url(assets/fonts/Mark-Simonson-Proxima-Nova-Black.otf) format('truetype');
  font-display: swap;
}

.maplibregl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-left {
  .maplibregl-ctrl, .maplibregl-ctrl-group, .mapboxgl-ctrl, .mapboxgl-ctrl-group {
    border-radius: 0px;
    border: 3px solid #EEEEEE;
    box-shadow: none;

    button {
      width: 40px;
      height: 40px;
    }

    .maplibregl-ctrl-zoom-out, .mapboxgl-ctrl-zoom-out {
      border-top: 3px solid #EEEEEE;
    }
  }
}

// Homepage map popup style
.mapboxgl-popup-content, .maplibregl-popup-content {
  width: 340px;
  padding: 0;

  @media screen and (max-width: 700px) {
    width: 290px ;
  }

  .maplibregl-popup-close-button, mapboxgl-popup-close-button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 32px;
    outline: none;
    content: '';

    ::after {
      content: "\2A09";
    }
  }

  .content {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
  }

  h1 {
    max-width: 90%;
    font: 700 24px 'Proxima Nova', serif;
    line-height: normal;
    margin-bottom: 4px;
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 700px) {
      font-size: 18px;
    }
  }

  .maplink {
    font: 400 16px Arial;
    color: #FF1A58;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 8px;

    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }

  .schedule {
    font: 400 14px Arial;
    padding-bottom: 12px;
    border-bottom: 1px solid #EEEEEE;

    @media screen and (max-width: 700px) {
      font-size: 12px;
    }

    small {
      font: 400 14px Arial;
      color: #6E6E6E;

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
    }
  }

  .chargers-list {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    h2 {
      margin: 0 0 4px;
      font: 700 14px Arial;
      color: #6E6E6E;

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      font: 400 16px Arial;
      margin: 0 0 4px;

      @media screen and (max-width: 700px) {
        font-size: 14px;
      }
    }
  }

  .network {
    small {
      font: 400 14px Arial;
      color: #6E6E6E;

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
    }

    .netlink {
      font: 400 14px Arial;
      color: #FF1A58;
      text-decoration: underline;
      cursor: pointer;

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
    }

    .no-link {
      font: 400 14px Arial;
      color: black;

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
    }
  }
}

app-charging-map {
  .mat-select-trigger {
    height: 100%;
  }
  .mat-select-value-text {
    font: 400 18px Arial;
  }

  @media screen and (max-width: 699px) {
    .mat-select-trigger {
      .mat-select-value {
        .mat-select-value-text {
          font-size: 16px;
        }
      }
    }
  }
}

app-charging-map {
  .mat-select-trigger {
    .mat-select-value {
      .mat-select-placeholder {
        color: black !important;
        font: 400 18px Arial !important;

        @media screen and (max-width: 699px){
          font-size: 16px !important;
        }
      }
    }
  }
}

.lvl-connectors-select {
  border: 1px solid black !important;
  border-radius: 8px !important;
  min-width: calc(100% + 16px) !important;

  .mat-option {
    .mat-pseudo-checkbox {
      width: 24px;
      height: 24px;
      border: 1.5px solid $secondary-dark-gray;
      border-radius: 4px;
    }

    .mat-pseudo-checkbox-checked {
      border: 1.5px solid $secondary-red;
      background-image: url('../src/assets/icons/check-icon.svg');
      background-color: $secondary-red;
      background-position: center;
      background-repeat: no-repeat;

      &::after {
        display: none;
      }
    }

    .mat-option-text {
      font: 400 16px Arial;

      @media screen and (max-width: 699px) {
        font: 400 14px Arial;
      }
    }
  }
}

entergy-input {
  .mat-form-field-suffix {
    display: flex;
    gap: 8px;
  }
}

.mat-slide-toggle-bar {
  width: 3rem;
  background-color: transparent;
}

.mat-slide-toggle.mat-checked {
  width: 3rem;
  height: 1.65rem !important;
  border-radius: 7.5rem;
  background: $secondary-red;

  .mat-slide-toggle-bar {
    transform: translate3d(.5rem, -0.03rem, 0);
  }

  .mat-slide-toggle-thumb {
    background: $primary-white;
  }
}

.mat-slide-toggle {
  width: 3rem;
  height: 1.65rem !important;
  border-radius: 7.5rem;
  background: $secondary-gray;

  .mat-slide-toggle-bar {
    transform: translate3d(0.3rem, -0.03rem, 0);
  }

  .mat-slide-toggle-thumb {
    background: $primary-white;
  }
}

.mat-slide-toggle-thumb-container {
  top: -2px !important;
}

.entergy-btn {
  font-family: 'Arial', serif;
  font-weight: 700;
  font-size: 16px;
  width: 192px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px !important;
}

.success-btn {
  background-color: $primary-black;
  color: $primary-white;

  &[disabled] {
    background: #B0B0B0;
    color: white !important;
  }
}

.back-btn {
  color: $primary-black;
  background-color: transparent;
}

.add-btn {
  color: $secondary-red;
  background-color: $secondary-white;
    &:hover {
      border: 1px $secondary-red solid !important;
    }
    ::ng-deep .mat-button-focus-overlay {
      background-color: transparent;
    }
}

.border-btn {
  color: $secondary-red;
  background-color: $secondary-white;
    border: 1px $secondary-red solid !important;
    &:hover {
      border: 2px $secondary-red solid !important;
    }
    ::ng-deep .mat-button-focus-overlay {
      background-color: transparent;
    }
}

.add-btn {
  .add-content {
    img {
      vertical-align: text-top;
      margin-right: 12px;
    }
  }
}

.alert-btn {
  background-color: $clickable-red-alert;
  color: $primary-white;
}

.field-row {
  display: flex;
  flex-direction: row;
}

.field {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-left: 16px;
  }

  mat-label {
    font-family: 'Arial', serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }

  input {
    margin-top: 4px;
    outline: none;
    padding: 12px;
    border: 1px solid #000000;
    border-radius: 8px;
    max-width: 100%;
    box-sizing: border-box;

    &::placeholder {
      font-family: 'Arial', serif;
      font-weight: 400;
      font-size: 14px;
      color: #B0B0B0;
    }
  }

  .small {
    input {
      margin-top: 8px;
      outline: none;
      padding: 12px;
      border: 1px solid #000000;
      border-radius: 8px;

      &::placeholder {
        font-family: 'Arial', serif;
        font-weight: 400;
        font-size: 14px;
        color: #B0B0B0;
      }
    }
  }
}

//TODO Apply this class to all labels
.select-label{
  margin-right: .6rem;
  color: #B0B0B0;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  align-items: center;
  padding: 12px !important;
  height: 41px;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-wrapper {
  padding: 0;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  border-top: 4px solid transparent;
}

.mat-select-arrow {
  border-left: 12px solid transparent !important;
  border-right: none !important;
  border-top: 6px solid transparent !important;
  border-image-source: url(./assets/icons/up-icon.svg) !important;
  border-image-repeat: stretch !important;
}

.mat-select-arrow-wrapper {
  height: 8px !important;
  align-items: flex-end !important;
}

app-charger-form-v2 {
  .mat-list-item-content-reverse {
    padding: 12px 16px !important;
  }

  .mat-list-option {
    height: auto !important;
  }

  .mat-list-text {
    padding-right: 0 !important;
  }
}

.mat-select-value {
  font-family: 'Arial', serif;
  font-weight: 400;
  font-size: 14px;
  color: #000;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.mat-form-field-flex {
  border: 1px solid #000000;
  border-radius: 8px !important;
}

.mat-form-field-infix {
  padding: 12px;
  border-top: 0;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 12.5px 0;
}

.mat-form-field-prefix {
  margin-left: 12px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}

.mat-slider {
  width: 100%;
  padding: 0 !important;
}

.mat-slider-thumb {
  background-color: #000 !important;
}

.mat-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb,
.mat-slider:not(.mat-slider-disabled).cdk-touch-focused .mat-slider-thumb,
.mat-slider:not(.mat-slider-disabled).cdk-program-focused .mat-slider-thumb {
  transform: scale(.7) !important;
}

.mat-slider-thumb-container {
  transition: none !important;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: $secondary-red;
  transition: none;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 0.5rem;
  border-radius: 8px
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-accent .mat-slider-thumb {
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  bottom: -15px;
  right: -15px;
}

.mat-slider-track-background {
  background-color: #eee;
}

.mat-dialog-container {
  border-radius: 0.75rem !important;
  box-shadow: none;

  .dialog-header {
    margin: 0;
  }

  .option {
    color: #6E6E6E;
  }
}

.mat-dialog-actions {
  margin: 0 !important;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.hr {
  border: none;
  border-bottom: 0.4rem solid $secondary-red;
  width: clamp(10rem, 10rem + 10vw, 19.875rem);
  margin-bottom: 1.5rem;
}

.hr-modal {
  color: $secondary-light-gray;
  height: 0.125rem;
  width: 100%;
}

.divider {
  border-top: 2px solid #EEEEEE !important;
  margin: 16px 0 !important;

  @media screen and (max-width: 1024px) {
    margin: 12px 0 !important;
  }
}

.operational-input {
  padding: 12px 0 !important;
  height: 41px;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    height: 40px;
  }

  span {
    cursor: pointer;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prefix-input-suffix {
    display: grid;
    grid-template-columns: fit-content(10%) auto fit-content(10%);

    input {
      width: 90%;
    }

    .prefix,
    .suffix {
      padding: 0;
      margin: 0;
      color: $secondary-gray;
    }
  }
}

.required-label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left:auto;

  &__f-placeholder{
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #B0B0B0;
    margin-top: 0.2rem;
  }
}

.entergy-input {
  @media screen and (max-width: 767px) {
    height: 40px;
  }
}

.mat-form-field-suffix,
.mat-form-field-prefix {
  span {
    font-family: 'Arial', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #B0B0B0;
  }
}

.operational-field {
  width: 100%;
  text-align: center;
  border: none !important;
  outline: none !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.entergy-checkbox .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
}

.entergy-checkbox .mat-checkbox-frame {
  border-width: 1px !important;
  border-color: #000 !important;
  border-radius: 4px !important;
}

.multiline-tooltip {
  display: flex;
  white-space: pre-line;
  font-size: 14px;
  width: 200px;
  height: 50px;
  padding: 12px !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.orange-tooltip {
  background: $tertiary-orange;
  color: $primary-white;
}

.red-tooltip {
  background: $clickable-red-alert;
  color: $primary-white;
}

.absolute-panel {
  .mat-expansion-panel-content {
    position: absolute;
  }
}

.opCo-inputs-panel {
  .mat-expansion-panel-body {
    padding: 0 16px
  }
  .opCo-inputs-panel-header {
    height: 24px;
    padding: 8px 16px;
    background: linear-gradient(0deg, rgba(238, 238, 238, 0.4), rgba(238, 238, 238, 0.4)), #FFFFFF;
    .opCo-inputs-panel-title {
      font-family: 'Arial';
      font-weight: 700;
      font-size: 14px;
      color: #6E6E6E;
    }
  }
}

.vahicle-inputs-panel{
  .vahicle-inputs-panel-header{
    background: linear-gradient(0deg, rgba(238, 238, 238, 0.4), rgba(238, 238, 238, 0.4)), #FFFFFF;
    .vahicle-inputs-panel-title{
      font-family: 'Arial';
      font-weight: 700;
      font-size: 14px;
      color: #6E6E6E;
    }
  }

.properties-body{
  padding: 0 24px;

    .row{
        &:nth-child(1){
            margin-top: 0 !important;
        }
    }
  }

  .vehicle-price-body{
    max-height: 215px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: .5rem;
    }

    &::-webkit-scrollbar-track {
      background: #EEEEEE;
    }

    &::-webkit-scrollbar-thumb {
      background: #B0B0B0;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #9A9A9A;
    }
  }
}

app-add-vehicle {

  .mat-expansion-panel-body {
    padding: 10px 0 0 0 !important;
  }

  .panel-content {
    .mat-list-text {
      flex-flow: row !important;
      align-items: flex-end !important;
    }
  }

  //.mat-select-value {
  //height: 42px;
  //}
}

.rotate-180deg {
  transform: rotate(180deg);
}

app-calculator {
  @media screen and (max-width: $max-tablet-width) and (min-width: $min-tablet-width), (max-width: $max-mobile-width) {
    entergy-input {
      .mat-form-field-flex {
        padding: 8px 16px;
        font-size: 12px;
        line-height: 14px;
        width: 80px;
      }

      .mat-form-field-prefix {
        margin-left: 4px;

        span {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

app-fuel-price {
  @media screen and (max-width: 767px) {
    entergy-input {
      .mat-form-field-flex {
        padding: 8px 16px;
        font-size: 12px;
        line-height: 14px;
        width: 80px;
      }

      .mat-form-field-prefix {
        margin-left: 4px;

        span {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

app-add-vehicle {
  @media screen and (max-width: 767px) {
    entergy-input-operational, entergy-input {
      .mat-form-field-flex {
        font-size: 12px;
        line-height: 14px;
        padding: 10.8px 16px;
      }

      .weird-suffix-based-spacing {
        display: none;
      }

      span:has(> img) {
        padding: 12px !important;
      }
    }
  }
}

.custom-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  background: $clickable-red-alert;
  border-radius: 0.125rem;
  width: 7.8125rem;
  height: 1.375rem;
}

.custom-tooltip-yellow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  background: #FF8833;
  border-radius: 0.125rem;
  width: 9.125rem;
  padding: 4px 0;
}

.delete-tooltip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  width: 2.8125rem;
  height: 1.375rem;
  background: $clickable-red-alert;
  border-radius: 0.125rem;
  font-family: 'Arial', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.edit-tooltip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  width: 1.9375rem;
  height: 1.375rem;
  background: $primary-black;
  border-radius: 0.125rem;
  font-family: 'Arial', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.dark-tooltip {
  background-color: #000;
  padding: 12px !important;
  color: #fff;
  font-family: 'Arial';
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .dark-tooltip {
    font-size: 12px;
  }
}

app-admin {
  .wrapper {
    .wrapper--content {
      .header {
        position: relative;

        h2 {
          font-family: 'Proxima Nova', serif;
          font-style: normal;
          font-weight: 700;
          font-size: 2rem;
          font-feature-settings: 'ss02' on;
          text-align: start;
          margin-bottom: .5rem;
        }

        hr {
          margin-inline-start: initial;
          width: 5%;
        }

        button.add-item, button.add-item-upload, button.add-item-add {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-flow: row nowrap;
          background: $secondary-red-opacity-10;
          border: none;
          padding: .75rem 1rem .75rem .75rem;
          border-radius: 2rem;
          cursor: pointer;
          align-items: center;

          .icon {
            padding: 0;
            scale: 0.6;
            color: $secondary-red;
            margin: 0 .25rem 0 0;
          }

          span {
            font-family: 'Arial', serif;
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
            font-feature-settings: 'kern' off;
            color: $secondary-red;
          }
        }
      }

      .division {
        & > * {
          margin-right: 1rem;
        }

        h3 {
          font-family: 'Proxima Nova', serif;
          font-style: normal;
          font-weight: 700;
          font-size: 1.25rem;
          text-align: start;
          font-feature-settings: 'ss02' on;
          display: inline;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    .wrapper {
      .wrapper--content {
        .header {
          h2 {
            font-size: 24px;
            line-height: 29px;
          }

          hr {
            width: 15%;
            margin-bottom: 8px;
          }

          button.add-item {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            flex-flow: row nowrap;
            background: $secondary-red-opacity-10;
            border: none;
            padding: .75rem 1rem .75rem .75rem;
            border-radius: 2rem;
            cursor: pointer;
            align-items: center;

            .icon {
              padding: 0;
              scale: 0.6;
              color: $secondary-red;
              margin: 0 .25rem 0 0;
            }

            span {
              font-family: 'Arial', serif;
              font-style: normal;
              font-weight: bold;
              font-size: 1rem;
              text-align: center;
              font-feature-settings: 'kern' off;
              color: $secondary-red;
            }
          }
        }

        .division {
          margin: 1rem 0 0.5rem 0;

          & > * {
            margin-right: 0.5rem;
          }

          h3 {
            font-size: 16px;
            line-height: 21px;
            margin: 16px 8px 8px 0;
          }
        }
      }
    }
  }
}

app-mileage-frequency {
  .mat-tab-header {
    border-bottom: none;

    .mat-tab-label-active {
      opacity: 1;
    }

    .mat-ink-bar {
      height: 4px;
    }

    .mat-tab-label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      font-feature-settings: 'ss02' on;
      border-bottom: 4px solid #B0B0B0;
      color:#6E6E6E;
      opacity: 1;
      }
      .mat-tab-label-active{
        opacity: 1;
        color: #000000;
        border-color:#FF1A58;
      }
  }

  .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 12px;
    border-radius: 8px
  }

  .mat-accent .mat-slider-thumb {
    height: 2rem;
    width: 2rem;
    bottom: -20px;
    right: -20px;
  }

  @media screen and (max-width: 767px){
    .mat-tab-header {
      .mat-tab-label {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

.set-up-modal {
  width: 620px;
  height: 830px;

  @media screen and (max-width: 1024px) {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 576px;
    height: 658px;

    entergy-input-operational, .operational-input, entergy-input {
      font-size: 12px;
      line-height: 14px;
      height: 36px;

      .weird-suffix-based-spacing {
        display: none;
      }

      .suffix, .prefix {
        font-size: 12px !important;
      }

      span:has(> img) {
        padding: 12px !important;
      }
    }

    .mat-dialog-container {
      padding: 24px !important;

      form {
        .row {
          .fuel-label {
            font-family: 'Arial', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-right: 32px;
          }

          .fuel-btns {
            button {
              height: 36px;
              padding: 12px 16px;

              span {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
      }

      .vehicle-sec {
        .delete-charger {
          margin-top: 8px;
          height: 44px;
          font-size: 12px;
          line-height: 14px;
          padding-left: 30px;
          padding-right: 30px;

          .cancel-delete-btn {
            gap: 15px;

            .cancel-btn, .delete-btn {
              width: 69px;
              height: 36px;
              border-radius: 54px;
            }
          }

        }

        .car-detail {
          .icon-padding {
            margin-right: 32px;
          }

          .icon {
            height: 48px;
          }
        }
      }
    }


    .electrification-plan {
      button {
        font-size: 12px;
        line-height: 14px;
        width: 128px;
        height: 40px;

        .add-content {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

.calculator-modal {
  width: 624px;
  height: 736px;

  .mat-dialog-container {
    border-radius: 12px !important;
    padding: 36px 32px 36px 32px;
  }

  app-current-fleet-v2 {
    .dialog-footer {
      display: flex;
      justify-content: space-between;
    }
    h1 {
      font-family: Proxima Nova,serif;
      font-weight: 700;
      font-size: 1.5rem;
      width: 75%;
      line-height: normal;
      margin-bottom: 8px;
    }
    .plan-header {
      font-family: Arial,serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      padding: 0.5rem 0 1.5rem;
    }
    .mat-divider{
      border-top-width: 2px;
      border-top-color: #B0B0B0;
      margin-bottom: 0.75rem;
    }
    @media screen and (max-width: 700px)  {
      .vahicle-inputs-panel .vehicle-price-body {
        height: 150px;
      }
    }
    @media screen and (max-width: 390px)  {
      .calculator-onboarding .vahicle-inputs-panel .vehicle-price-body {
        height: 225px;
        max-height: 225px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 576px;
    height: 608px;

    entergy-input-operational, .operational-input, entergy-input {
      font-size: 12px;
      line-height: 14px;
      height: 36px;

      .weird-suffix-based-spacing {
        display: none;
      }

      .suffix, .prefix {
        font-size: 12px !important;
      }

      span:has(> img) {
        padding: 12px !important;
      }
    }

    .mat-dialog-container {
      padding: 32px 5% !important;

      form {
        .row {
          .fuel-label {
            font-family: 'Arial', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-right: 32px;
          }

          .fuel-btns {
            button {
              height: 36px;
              padding: 12px 16px;

              span {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
      }

      .vehicle-sec {
        .delete-charger {
          margin-top: 8px;
          height: 44px;
          font-size: 12px;
          line-height: 14px;
          padding-left: 30px;
          padding-right: 30px;

          .cancel-delete-btn {
            gap: 15px;

            .cancel-btn, .delete-btn {
              width: 69px;
              height: 36px;
              border-radius: 54px;
            }
          }

        }

        .car-detail {
          .icon-padding {
            margin-right: 32px;
          }

          .icon {
            width: 16px;
            height: 16px;
            border-radius: 54px;
            padding: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .mat-dialog-container {
      border-radius: 0 !important;
      padding: 36px 16px;
      overflow-x: hidden;
    }
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100%;
    width: 100vw;
  }
  @media screen and (max-width: 700px) {
    app-add-ev {
      .fuel-label {
        margin-right: 0 !important;
      }
    }
  }
}

.edit-electric-vehicle-modal {
  width: 624px;
  height: 736px;

  .mat-select-trigger {
    padding: 0 16px;
  }

  .mat-dialog-container {
    border-radius: 12px !important;
    padding: 36px 32px 36px 32px;
  }

  .mat-tab-label {
    font-size: 16px !important;
  }

  @media screen and (max-width: 1024px) {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 576px;
    height: 608px;

    entergy-input-operational, .operational-input, entergy-input {
      font-size: 12px;
      line-height: 14px;
      height: 36px;

      .weird-suffix-based-spacing {
        display: none;
      }

      .suffix, .prefix {
        font-size: 12px !important;
      }

      span:has(> img) {
        padding: 12px !important;
      }
    }

    .mat-dialog-container {
      padding: 32px 5% !important;

      form {
        .row {
          .fuel-label {
            font-family: 'Arial', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-right: 32px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .mat-dialog-container {
      border-radius: 0 !important;
      padding: 36px 16px;
    }
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100%;
    width: 100vw;
  }
}

app-general-settings {
  .field {
    input {
      margin-top: 0;
      padding: 0;
    }
  }

  entergy-input-operational {
    .operational-input {
      .middle-part {
        flex-grow: 1;

        input {
          flex-grow: 1;
          max-width: 60px;
        }

        .weird-suffix-based-spacing {
          display: none;
        }
      }
    }
  }
}

app-current-fleet-form {
  .mat-divider {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.company-view-rates-modal {
  .mat-dialog-container {
    overflow: hidden;
    padding: 32px;
  }
}

.dashboard-tabs .mat-tab-header .mat-tab-label {
  padding: 0;
  min-width: 94px;
}

app-calculator .mat-tab-header .mat-tab-label {
  padding: 0;
  min-width: 94px;
}


//*ADHOC fix - this should be integrated to main style rules.*//
app-charger-form-v2 {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
  }

  .mat-expansion-panel-body {
    padding: 10px 0 0 !important;
  }
}

//*ADHOC fix - this should be integrated to main style rules.*//
.calculator-modal {
  app-fuel-price {
    @media screen and (max-width: 767px) {
      .mat-form-field-flex {
        padding: 0;
      }
    }
  }
}

.dashboard-tabs {
  .mat-tab-header {
    border-bottom: none;
    margin-bottom: 0 !important;

    .mat-tab-label-content {
      font-size: 16px;
    }
    .mat-tab-label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      font-feature-settings: 'ss02' on;
      border-bottom: 4px solid #B0B0B0;
      color:#6E6E6E;
      opacity: 1;
    }
    .mat-tab-label-active{
      opacity: 1;
      color: #000000;
      border-color:#FF1A58;
    }
  }
  .mat-tab-body-content {
    overflow: hidden !important;
  }
}

app-calculator {
  .mat-tab-header {
    border-bottom: none;
    margin-bottom: 0 !important;

    .mat-tab-label-content {
      font-size: 24px;
    }
    @media screen and (max-width: 700px) {
      .mat-tab-label-content {
        font-size: 16px;
      }
    }
    .mat-tab-label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      font-feature-settings: 'ss02' on;
      border-bottom: 4px solid #B0B0B0;
      color:#6E6E6E;
      opacity: 1;
    }
    .mat-tab-label-active{
      opacity: 1;
      color: #000000;
      border-color:#FF1A58;
    }
  }
  .mat-tab-body-content {
    overflow: hidden !important;
  }
}

.how-we-calculate-this-modal {
  width: 700px;
  height: 780px;

  .mat-dialog-container {
    border-radius: 12px !important;
    padding: 24px;
  }

  .mat-tab-header {
    border-bottom: none;
    margin-bottom: 0 !important;

    .mat-tab-label {
      align-items: flex-end;
      border-bottom: 2px solid #EEEEEE;
      margin-bottom: 0;
      padding-bottom: 0.25rem;
    }

    .mat-tab-label-content {
      font-size: 16px;
    }

    .mat-tab-label-active {
      color: $secondary-red;
      opacity: 1;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    height: auto;

    .mat-dialog-container {
      border-radius: 0 !important;
    }
  }

  @media screen and (max-width: 500px) {
    .mat-dialog-container {
      padding: 16px;
    }

    .mat-tab-header {
      .mat-tab-label {
        padding: 0 0.25rem;
      }
      .mat-tab-label-content {
        font-size: 14px;
      }
    }
  }
}

.calculators-redirect-modal {
  width: 650px;
  height: auto;

  .mat-dialog-container {
    border-radius: 12px !important;
    padding: 0;
  }

  @media screen and (max-width: 1200px) {
    width: 575px;
  }

  @media screen and (max-width: 700px) {
    width: 100%;

    .mat-dialog-container {
      border-radius: 0 !important;
    }
  }
}

app-current-fleet-v2, app-electric-equivalent-v2, app-company-settings-modal{
  .mat-expansion-panel-header.mat-expanded:hover, .mat-expansion-panel-header.mat-expanded:focus {
    background: linear-gradient(0deg, rgba(238, 238, 238, 0.4), rgba(238, 238, 238, 0.4)), #FFFFFF
  }
}
app-add-vehicle {
  .mat-expansion-panel-header.mat-expanded:hover, .mat-expansion-panel-header.mat-expanded:focus {
    background: linear-gradient(0deg, rgba(238, 238, 238, 0.4), rgba(238, 238, 238, 0.4)), #FFFFFF
  }
}

app-internal-calculator {
  .mat-expansion-panel {
    box-shadow: unset !important;
    .mat-expansion-panel-header {
      padding: 1rem;
    }
    .mat-content {
      flex-direction: column;
    }
    .mat-expanded {
      .mat-expansion-panel-header-description {
        display: none;
      }
    }
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
  .mat-expansion-panel-header-description {
    color: $secondary-red;
    font-weight: 700;
  }
}

app-calculator{
  .mat-expansion-panel {
    box-shadow: unset !important;
    border-radius: 0.75rem;
    .mat-expansion-panel-header {
      padding: 1rem;
      height: 64px;
    }
    .mat-content {
      flex-direction: column;
    }
    .mat-expanded {
      .mat-expansion-panel-header-description {
        display: none;
      }
    }
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
  .mat-expansion-panel-header-description {
    color: $secondary-red;
    font-weight: 700;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
  }
}


.edit-charger-make .mat-dialog-container {
  padding: 2.25rem 2rem;
}

.delete-charger-make .mat-dialog-container {
  padding: 2.25rem 2rem;
}

//*ADHOC fix - this should be integrated to main style rules.*//
// Fix modal rules
  .mat-dialog-container {
    border-radius: 12px;
    padding: 36px 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

app-charging{
  .mat-expansion-panel-body{
    padding: 0;
  }
   .mat-list-text{
    flex-direction: row !important;
  }
}

app-add-ev{

  .ngx-slider-tick:nth-child(1) {
    .ngx-slider-inner-tooltip {
      width: 60px;
      text-align: left;
      margin-left: 40px;
    }

    .ngx-slider-tick-legend{
      width: 60px;
      text-align: left;
      margin-left: 15px;
    }
  }

  .ngx-slider-tick:nth-last-child(1) {
    .ngx-slider-inner-tooltip {
      width: 60px;
      text-align: right;
      margin-right: 40px;
    }

    .ngx-slider-tick-legend{
      width: 60px;
      text-align: right;
      right: -24px;
    }
  }


  .custom-slider {

      .ngx-slider {
        .ngx-slider-tick {
          width: 18px;
          height: 18px;
          margin-left: 0px;
          border-radius: 9px;
          top: -2px;
        }

        .ngx-slider-pointer {
          background-color: $primary-black;
          width: 18px;
          height: 18px;
          top: -5px;
          margin-left: 0px;
          &::after{
            display: none;
          }
        }

        .ngx-slider-tick-value {
          color: var(--primary-colors-black, #000);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.168px;
          top: -25px;
        }

        .ngx-slider-tick-legend {
          color: var(--primary-colors-black, #000);
          font-family: Arial;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          top: 25px;
            &::after{
              content: 'kw';
            }
        }

        .ngx-slider-pointer.ngx-slider-active:after {
          background-color: $primary-black;
        }

        .ngx-slider-tick {
          background: $secondary-light-gray;
          width: 18px;
          height: 18px;
        }

        .ngx-slider-tick.ngx-slider-selected {
          background: var(--secondary-colors-red, #FF1A58);
        }
      }
    }

  .custom-slider .ngx-slider .ngx-slider-bar {
    background: $secondary-light-gray;
    height: 8px;
    right: 4px;
    left: 8px;
    width: auto;
  }

  .custom-slider .ngx-slider .ngx-slider-selection {
  background: var(--secondary-colors-red, #FF1A58)
  }
}

app-charger-average-prices-modal{
  td.mat-cell:first-child{
    display: flex;
  }
}

:root {
  --doc-height: 100%;
}
html,
body {
  height: 100vh; /* fallback for Js load */
  height: var(--doc-height);
}

@import "./recaptcha"; /*To center the recaptcha*/
@import "app/modules/common/tool-tip/tool-tip.global";
@import "app/modules/common/info-modal/info-modal.global";
@import "app/modules/calculator/report/get-report-modal/get-report-modal.global";

@import "app/modules/admin/company-settings/company-delete-modal/company-delete-modal.global";
@import "app/modules/internal-calculator/set-up-modal/setup-modal-v2.global";
